
import { defineComponent } from 'vue';
import { mapMutations } from 'vuex';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
// import BaseDial from '@/components/Base/BaseDial.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import constRouter from '@/constants/constRouter';
import { registerPhone } from '@/apis/ApiAuth';
import * as Analytics from '@/services/analytics';

export default defineComponent({
  components: {
    BaseButton,
    BaseInput,
    // BaseDial,
    BaseTextError,
    DefaultLayout,
  },
  name: 'RegisterPhone',
  data() {
    return {
      phoneNumber: '',
      labelError: '',
      loading: false,
    };
  },
  computed: {
    phone(): string {
      let str = this.phoneNumber.replace(/ /g, '');
      for (let i = 0; i < str.length; i++) {
        if (i === 3 || i === 7) {
          str = str.slice(0, i) + ' ' + str.slice(i, str.length);
        }
      }
      return str;
    },
  },
  methods: {
    ...mapMutations({
      setPhone: 'setPhone',
    }),
    handleSubmit() {
      this.phoneNumber = this.phoneNumber.replace(/ /g, '');
      if (this.phoneNumber.length < 10 || this.phoneNumber[0] !== '0') {
        this.labelError = 'Số điện thoại không hợp lệ';
        return;
      } else {
        this.loading = true;
        registerPhone(this.phoneNumber)
          .then((res: any) => {
            if (res.statusCode === 200) {
              this.setPhone(this.phoneNumber);
              this.$router.push({
                name: constRouter.CONFIRM_OTP.name,
              });
              this.loading = false;
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
            this.labelError = err?.data.message;
          });
        //analytics
        Analytics.eventK2A(
          'REGISTER',
          'INPUT_PHONE',
          'Nhập số điện thoại',
          this.phoneNumber,
        );
      }
    },
    inputPhone(num: number) {
      if (this.phoneNumber.length < 10) {
        this.phoneNumber = this.phoneNumber + num;
      }
    },
    clearPhone() {
      this.phoneNumber = this.phoneNumber.slice(0, -1);
    },
  },
});
