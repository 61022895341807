
import { defineComponent } from 'vue';
import BaseLoading from '@/components/Base/BaseLoading.vue';

export default defineComponent({
  name: 'BaseButton',
  components: { BaseLoading },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {},
});
