<template>
  <div
    class="messeage-error"
    :class="{
      'text-center': textCenter,
      shake: errorMessages,
    }"
  >
    <span>{{ errorMessages }}</span>
  </div>
</template>

<script>
export default {
  name: 'BaseTextError',
  props: {
    errorMessages: {
      type: String,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.messeage-error {
  min-height: 15px;
  color: #ff5252;
  font-size: 13px;
}
</style>
