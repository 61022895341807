declare global {
  interface Window {
    K2A: any;
  }
}
function setupK2A() {
  if (window.K2A) {
    window.K2A.setup({
      uid: +process.env.VUE_APP_K2A_UID,
      campaign: +process.env.VUE_APP_K2A_CAMP,
    });
  } else {
    setTimeout(() => {
      console.log(window.K2A);
      setupK2A();
    }, 500);
  }
}

export function eventK2A(
  category: string,
  action: string,
  label: string,
  value: any,
) {
  window.K2A.event(category, action, label, value);
}

setupK2A();
