import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b2d049a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "absolute top-0 left-0 w-full h-full flex items-center justify-center"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_loading = _resolveComponent("base-loading")!

  return (_openBlock(), _createElementBlock("button", {
    disabled: _ctx.disabled,
    class: _normalizeClass(["hover:opacity-90 base-button text-center transition ripple p-4 focus:outline-none relative", {
      'loading text-app': _ctx.loading,
    }])
  }, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, [
          _createVNode(_component_base_loading)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      class: _normalizeClass(["whitespace-nowrap", {
        'opacity-0': _ctx.loading,
      }])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2)
  ], 10, _hoisted_1))
}